import { Footer } from "@/components/Footer"
import GAScript from "@/components/GAScript"
import { Header } from "@/components/Header"
import { getNavigations } from "@/data/navigations"
import { MantineTheme, createStyles } from "@mantine/core"
import { FC, ReactNode } from "react"

const useStyles = createStyles((theme: MantineTheme) => ({
  container: {
    padding: "0 130px",
  },
}))

interface Props {
  children: ReactNode
  navigations?: Awaited<ReturnType<typeof getNavigations>>
  settings?: any
}

export const MainLayout: FC<Props> = ({ children, navigations, settings }) => {
  const { classes } = useStyles()

  return (
    <main>
      <GAScript id={settings?.attributes?.GoogleAnalyticsTrackingID}></GAScript>
      <Header navigations={navigations} />
      {children}
      <Footer navigations={navigations} />
    </main>
  )
}
